import React from 'react'
import {
  Flex,
  IconButton,
  Spacer,
  Text,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react'

import { SunIcon, MoonIcon } from '@chakra-ui/icons'

export const SimpleFooter: React.FC = () => {
  const bg = useColorModeValue('brand.700', 'gray.800')
  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  return (
    <footer>
      <Flex
        direction={['column', 'column', 'row']}
        w='full'
        py={2}
        px={[2, 4, 8]}
        align='center'
      >
        <Spacer />
        <Text fontSize='sm'>
          Copyright &copy; {new Date().getFullYear()} Antti Suomalainen.
        </Text>
        <Spacer />

        <IconButton
          aria-label='Switch color mode'
          icon={isDark ? <SunIcon /> : <MoonIcon />}
          // ischecked={isDark}
          onClick={toggleColorMode}
          size='sm'
          variant='ghost'
          colorScheme={isDark ? 'yellow' : 'blue'}
        />
      </Flex>
    </footer>
  )
}
export default SimpleFooter
