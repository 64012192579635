import React, { ReactNode } from 'react'
import { Box, Container, Flex } from '@chakra-ui/react'

import Navbar from './nav'
import SimpleFooter from './footer'


interface LayoutProps {
  children?: ReactNode
}
export default function Layout({ children }: LayoutProps) {
  return (
    <Container maxW='container.xl'>
      <Flex direction='column' minH='100vh'>
        {/* <Header title="Antti Suomalainen" /> */}
        <Navbar />

        <Flex grow={1} px={[2, 4, 8]} width='100%'>
          <Box w='100%'>{children}</Box>
        </Flex>

        <SimpleFooter />
      </Flex>
    </Container>
  )
}
