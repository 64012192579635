import * as React from 'react'
import { Heading, HStack, IconButton, Button } from '@chakra-ui/react'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

import { HamburgerIcon } from '@chakra-ui/icons'
import { Link } from 'gatsby'

const menuItems = [
  <Button as={Link} variant='ghost' key='home' to='/'>
    Home
  </Button>,
  <Button as={Link} variant='ghost' key='about' to='/about'>
    About
  </Button>,
  <Button as={Link} variant='ghost' key='portfolio' to='/portfolio'>
    Portfolio
  </Button>,
  <Button as={Link} variant='ghost' key='portfolio' to='/contact'>
  Contact
</Button>,
]
declare module 'react' {
  namespace JSX {
    interface IntrinsicElements {
      nav: {
        [elemName: string]: any
      }
    }
  }
}
export const Navbar: React.FC = () => {
  return (
    <nav>
      <HStack py={4} px={[2, 4, 8]} justify='space-between'>
        <Heading size='lg'>
          <Link to='/'>Antti Suomalainen</Link>
        </Heading>
        <HStack display={{ base: 'none', md: 'block' }} spacing={3}>
          {menuItems}
        </HStack>
        <Menu>
          <MenuButton
            display={{ base: 'block', md: 'none' }}
            as={IconButton}
            aria-label='Menu'
            icon={<HamburgerIcon />}
          />

          <MenuList>
            <MenuItem as={Link} to='/'>
              Home
            </MenuItem>
            <MenuItem as={Link} to='/about'>
              About
            </MenuItem>
            <MenuItem as={Link} to='/portfolio'>
              Portfolio
            </MenuItem>
            <MenuItem as={Link} to='/contact'>
              Contact
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </nav>
  )
}
export default Navbar
